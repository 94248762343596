

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

 /* .Page {
   display: flex;
   flex-direction: column;
   align-items: center;
   font-family: revert;
   font-size: 3vh;
   font-weight: bolder;
 } */

.Scroller {
  overflow-y: scroll;
}

.HoverIcon :hover {
  background-color: white;
  border-radius: 10px;
}

.CustomSize {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #888888;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #272727;
}

 /* Extra small devices (phones, 600px and down) */
 /* @media only screen and (max-width: 600px) {
   font-size: 9pt;
 } */

 /* Small devices (portrait tablets and large phones, 600px and up) */
 /* @media only screen and (min-width: 600px) {
   font-size: 20pt;
 } */

 /* Medium devices (landscape tablets, 768px and up) */
 /* @media only screen and (min-width: 768px) {
   font-size: 40pt;
 } */

 /* Large devices (laptops/desktops, 992px and up) */
 /* @media only screen and (min-width: 992px) {
   font-size: large;
 } */

 /* Extra large devices (large laptops and desktops, 1200px and up) */
 /* @media only screen and (min-width: 1200px) {
   font-size: larger;
 } */